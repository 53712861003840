import {NotificationCenterItemContainer} from '@rapid7/rapid7-ui';
import {useState} from 'react';
import {LOCAL_STORAGE_KEYS} from './objects/fedRampNotification';

const TEXT = 'If you are currently operating in Federal Information Processing Standard (FIPS) mode,' +
  ' all scan engines must be on a minimum of version 6.6.274 ahead of the encryption protocols upgrade expected on' +
  ' February 12, 2025. If you do not take action, you may experience interruptions to your authenticated scans. ' +
  'Click See Details for more information. No action is required if you are not operating in FIPS mode.';

const FedRampNotification = () => {
    const [showNotification, setShowNotification] = useState(() => {
        return !localStorage.getItem(LOCAL_STORAGE_KEYS.IS_FIPS_NOTIFICATION_READ);
    });

    if (!showNotification) {
        return null;
    }

    return (
        <NotificationCenterItemContainer
            key="fedRampNotification"
            includeIcon={false}
            notification={{ text: TEXT }}
            onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                window.open('https://docs.rapid7.com/nexpose/fips-mode-required-update-guide', '_blank');
            }}
            onDismiss={() => {
                localStorage.setItem(LOCAL_STORAGE_KEYS.IS_FIPS_NOTIFICATION_READ, 'true');
                setShowNotification(false);
            }}
        >
            <>
                {TEXT}
            </>
        </NotificationCenterItemContainer>
    );
};

export default FedRampNotification;

import { ProductEdition, UserPrivilege } from 'insightvm-ui-nav-menus';

export default ({
    admin: isAdmin = false,
    canUserSeeAdminTab = false,
    isCloudEnabled = false,
    productEdition,
    featureSet: { licensedOptions = [], ticketingSupported = false },
    oemConfig: { ticketingEnabled = false },
    userPrivileges = []
}) => {
    if (!Array.isArray(userPrivileges)) {
        return [];
    }

    let privileges = [
        UserPrivilege.HOME,
        UserPrivilege.ASSET,
        UserPrivilege.VULNERABILITY,
        licensedOptions.includes('INSIGHT_VM') ? UserPrivilege.ALL_POLICIES : UserPrivilege.POLICIES,
        UserPrivilege.REPORTS
    ];

    // If the user is Cloud enabled and has access to InsightVM, then they have access to the IVM features
    if (isCloudEnabled) {
        privileges = privileges.concat([UserPrivilege.DASHBOARD, UserPrivilege.MANAGEMENT]);
        if (userPrivileges.includes('createTickets')) {
            privileges.push(UserPrivilege.REMEDIATION);
        }
    }

    if (ticketingEnabled && ticketingSupported) {
        privileges.push(UserPrivilege.TICKETS);
    }

    if (isCloudEnabled && productEdition === ProductEdition.INSIGHT_VM) {
        privileges.push(UserPrivilege.GOALS);
    }

    if (productEdition === ProductEdition.INSIGHT_VM && isAdmin) {
        privileges.push(UserPrivilege.AUTOMATION);
    }

    if (licensedOptions.includes('ADAPTIVE_SECURITY') && isAdmin) {
        privileges.push(UserPrivilege.ACTIONS);
    }

    if (canUserSeeAdminTab) {
        privileges.push(UserPrivilege.ADMIN);
    }

    if (userPrivileges.includes('manageSites')) {
        privileges.push(UserPrivilege.MANAGE_SITES);
    }

    if (userPrivileges.includes('createReports')) {
        privileges.push(UserPrivilege.CREATE_REPORTS);
    }

    if (userPrivileges.includes('manageAssetGroups')) {
        privileges.push(UserPrivilege.MANAGE_ASSET_GROUPS);
    }

    if (userPrivileges.includes('manageDynamicAssetGroups')) {
        privileges.push(UserPrivilege.MANAGE_DYNAMIC_ASSET_GROUPS);
    }

    if (userPrivileges.includes('manageTags')) {
        privileges.push(UserPrivilege.MANAGE_TAGS);
    }

    return privileges;
};
